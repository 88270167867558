import { useEffect, useState } from 'react';

export enum ScrollDirection {
  DOWN = -1,
  NONE = 0,
  UP = 1,
}

type Props = {
  thresholdPixels?: number;
  off?: boolean;
};

const useScrollDirection = ({ thresholdPixels, off }: Props) => {
  const [scrollDir, setScrollDir] = useState(ScrollDirection.NONE);

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      setScrollDir(
        scrollY > lastScrollY ? ScrollDirection.DOWN : ScrollDirection.UP
      );
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDirection);
        ticking = true;
      }
    };

    /**
     * Bind the scroll handler if `off` is set to false.
     * If `off` is set to true reset the scroll direction.
     */
    !off
      ? window.addEventListener('scroll', handleScroll)
      : setScrollDir(ScrollDirection.NONE);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [thresholdPixels, off]);

  return scrollDir;
};

export default useScrollDirection;
