import React, { Dispatch, useState } from 'react';

export const PreviewContext = React.createContext<{
  previewMode: boolean;
  setPreviewMode: Dispatch<any>;
}>({
  previewMode: false,
  setPreviewMode: () => null,
});

export function PreviewProvider({ children }: { children: React.ReactNode }) {
  const [previewMode, setPreviewMode] = useState(false);

  return (
    <PreviewContext.Provider
      value={{
        previewMode,
        setPreviewMode,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
}
