import React, { useEffect, useState } from 'react';
import { Client } from '../prismic-configuration';
import { PrismicCategories } from '../types/prismic';

export const CategoriesContext = React.createContext<{
  categories: PrismicCategories | null;
}>({
  categories: null,
});

export function CategoriesProvider({ children }: { children: React.ReactNode }) {
  const client = Client();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    const cats = await client.getSingle('categories', {});
    setCategories(cats);
  };

  return (
    <CategoriesContext.Provider
      value={{
        categories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
}
