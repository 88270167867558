import Prismic from '@prismicio/client';
// import { Routes } from './helpers/constants';
export const apiEndpoint = 'https://15percentpledge.cdn.prismic.io/api/v2';
export const accessToken =
  'MC5ZRFpuWXhFQUFDQUFOb3BP.77-9IO-_ve-_ve-_ve-_vSzvv71p77-977-9Hzjvv70wR--_ve-_vXBz77-977-977-9DQE677-9Uk3vv71977-9';

// Client method to query documents from the Prismic repo
export const Client = (req = null) =>
  Prismic.client(apiEndpoint, createClientOptions(req, accessToken));

const createClientOptions = (req = null, prismicAccessToken = null) => {
  const reqOption = req ? { req } : {};
  const accessTokenOption = prismicAccessToken
    ? { accessToken: prismicAccessToken }
    : {};
  return {
    ...reqOption,
    ...accessTokenOption,
  };
};

export const linkResolver = (doc) => {
  if (doc.type === 'job-listing') {
    return `/jobs-listing/${doc.uid}`;
  }

  if (doc.type === 'homepage') {
    return '/';
  }

  return `/${doc.uid}`;
};

export const hrefResolver = (doc) => {
  return `/${doc.uid}`;
};
