import React from 'react';
import { ErrorMessage, Field, useField } from 'formik';
import ErrorMessageComponent from '../ErrorMessageComponent';
import styles from './form-ui.module.sass';

type Props = {
  type?: string;
  name: string;
  label: string;
  customClass?: string;
  isBox?: boolean;
  usePlaceholder?: boolean;
  uppercase?: boolean;
  size?: string;
  maxLength?: number;
  showCounter?: boolean;
  dark?: boolean;
  light?: boolean;
  row?: boolean;
};

const FormTextField = ({
  type,
  name,
  label,
  customClass,
  isBox,
  usePlaceholder,
  uppercase,
  size,
  maxLength,
  showCounter,
  dark,
  light,
}: Props) => {
  const [field] = useField(name);
  const inputMaxLength = maxLength ? maxLength : isBox ? 300 : 100;
  let inputClassName = size === 'large' ? 'input-large' : '';
  if (uppercase) inputClassName += ' uppercase';
  if (dark) inputClassName += ` ${styles.dark}`;
  if (light) inputClassName += ` ${styles.light}`;

  const fieldValueLength = field.value ? field.value.length : 0;

  return (
    <div className={`${styles.textfield} ${customClass ? customClass : ''}`}>
      {!usePlaceholder && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        as={isBox ? 'textarea' : 'input'}
        type={type ? type : 'text'}
        id={name}
        name={name}
        placeholder={usePlaceholder ? label : ''}
        className={inputClassName}
        maxLength={inputMaxLength}
      />
      <ErrorMessage
        name={name}
        render={(msg) => <ErrorMessageComponent error={msg} />}
      />
      {showCounter && (
        <span className={styles.counter}>
          {fieldValueLength}/{inputMaxLength}
        </span>
      )}
    </div>
  );
};

export default FormTextField;
