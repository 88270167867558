import React, { Dispatch, useState } from 'react';

export enum RegisterSteps {
  TYPE_CHOICE,
  USER,
  BUSINESS,
  BUSINESS_COMPLETED,
  CORPORATION,
  CORPORATION_COMPLETED,
}

export const RegisterContext = React.createContext<{
  registerStep: RegisterSteps;
  setRegisterStep: Dispatch<any>;
}>({
  registerStep: RegisterSteps.USER,
  setRegisterStep: () => null,
});

export function RegisterProvider({ children }: { children: React.ReactNode }) {
  const [registerStep, setRegisterStep] = useState(RegisterSteps.USER);
  return (
    <RegisterContext.Provider
      value={{
        registerStep,
        setRegisterStep,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
}
