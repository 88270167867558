import React, { Dispatch, useEffect, useState } from 'react';
import { ApolloError, useLazyQuery, useApolloClient } from '@apollo/client';
import { Q_USER } from '../graphql/user';
import { User } from '../types/user';
import { setupRollbarUser } from '../helpers/errors';

type UserPayload = {
  me: User;
};

export const AuthContext = React.createContext<{
  authInitialized: boolean;
  user: User | null;
  setUserToken: Dispatch<any>;
  logout: () => void;
  reloadUser: () => void;
}>({
  authInitialized: false,
  user: null,
  setUserToken: async () => null,
  logout: async () => null,
  reloadUser: () => null,
});

let browserStorage = null;

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const apolloClient = useApolloClient();
  const [authInitialized, setInitialized] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [user, setUser] = useState(null);

  const [getUser] = useLazyQuery(Q_USER, {
    fetchPolicy: 'network-only',
    context: {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    },
    onCompleted(data: UserPayload) {
      setUser(data.me);
      setInitialized(true);
      // setupRollbarUser(data.me.id);
    },
    onError(error: ApolloError) {
      console.error('error: ', error.message);
    },
  });

  useEffect(() => {
    browserStorage = localStorage;
    const storedToken = browserStorage.getItem('token');
    if (storedToken) {
      setUserToken(storedToken);
    } else {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (userToken) {
      if (browserStorage) browserStorage.setItem('token', userToken);
      getUser();
    } else {
      if (browserStorage) browserStorage.removeItem('token');
    }
  }, [userToken]);

  const reloadUser = () => {
    getUser();
  };

  const logout = () => {
    setUserToken(null);
    setUser(null);
    apolloClient.resetStore();
  };

  return (
    <AuthContext.Provider
      value={{
        authInitialized,
        user,
        setUserToken,
        reloadUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
