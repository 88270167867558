import React from 'react';
import styles from './loading-indicator.module.sass';

type Props = {
  inline?: boolean;
  small?: boolean;
  button?: boolean;
};

const LoadingIndicator = ({ inline, small, button }: Props) => {
  return (
    <div
      className={`${styles.wrapper} ${inline ? styles.inline : ''} ${
        button ? styles.button : ''
      }  ${small ? styles.small : ''}`}
    >
      <div className={styles.logo} />
    </div>
  );
};

export default LoadingIndicator;
