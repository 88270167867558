import React, { Dispatch, useState } from 'react';

export const SearchFilterContext = React.createContext<{
  searchInput: string;
  setSearchInput: Dispatch<any>;
  filterCategories: [] | null;
  setFilterCategories: Dispatch<any>;
}>({
  searchInput: '',
  setSearchInput: () => null,
  filterCategories: null,
  setFilterCategories: () => null,
});

export function SearchFilterProvider({ children }: { children: React.ReactNode }) {
  const [searchInput, setSearchInput] = useState(null);
  const [filterCategories, setFilterCategories] = useState(null);

  return (
    <SearchFilterContext.Provider
      value={{
        searchInput,
        setSearchInput,
        filterCategories,
        setFilterCategories,
      }}
    >
      {children}
    </SearchFilterContext.Provider>
  );
}
