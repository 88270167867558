import React from 'react';

import styles from './error-message.module.sass';

interface Props {
  customClass?: any;
  error: string;
}

const ErrorMessageComponent = ({ customClass, error }: Props) => {
  return (
    <div className={`${styles.error} ${customClass ? customClass : ''}`}>
      {error}
    </div>
  );
};

export default ErrorMessageComponent;
